@font-face {
  font-family: "VagRoundedRegular";
  font-weight: 400;
  font-style: normal;
  src: url("../public/fonts/VAG\ Rounded\ Regular.ttf");
}
@font-face {
  font-family: "VagRoundedBold";
  font-weight: 800;
  font-style: normal;
  src: url("../public/fonts/VAG\ Rounded\ Bold.ttf");
}
